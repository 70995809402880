<template>
    <b-modal :ref="modalRef" @hidden="destroyModal" header-class="center-modal-title"
             size="lg" :title="title" hide-footer>
        <div class="text-center">
            <b-img :src="imageSrc" class="mb-5 mt-3 seasonal-image"/>
            <p v-if="text" class="mb-4">{{ text }}</p>

            <b-button @click="hideModal" variant="secondary">Merci</b-button>
        </div>
    </b-modal>
</template>

<script>
    import modalMixin   from '@/mixin/modalMixin';
    import {requireImg} from '@/util/resources';

    export default {
        name: "SeasonalEventModal",
        mixins: [modalMixin],
        props: {
            title: {
                type: String,
                required: true
            },
            image: {
                type: String,
                required: true
            },
            text: {
                type: String,
                required: false
            }
        },
        data: () => ({
            modalRef: 'seasonalEventModal'
        }),
        computed: {
            imageSrc() {
                return requireImg('seasonalEvents/' + this.image);
            }
        }
    }
</script>

<style scoped>
    .seasonal-image {
        max-width: calc(100% - 1rem);
        filter: drop-shadow(0 0 1rem black);
    }
</style>